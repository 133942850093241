import React from 'react';

const Logo = () => {
  return (
    <svg viewBox="0 0 768 162">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-55.000000, -527.000000)">
          <g transform="translate(56.000000, 528.000000)">
            <g transform="translate(0.987063, 0.987805)">
              <path
                d="M79.978276,103.365854 L48.683053,103.365854 L64.329639,76.0335366 L79.978276,48.7012195 L95.6269129,76.0335366 L111.27555,103.365854 L79.978276,103.365854 Z M79.978276,96.5943057 L60.6461591,96.5943057 L70.3122175,79.710938 L79.978276,62.8275703 L89.6443344,79.710938 L99.3103928,96.5943057 L79.978276,96.5943057 Z M79.978276,89.7545045 L72.6810479,89.7545045 L76.3296619,83.3800663 L79.978276,77.0076964 L83.62689,83.3800663 L87.275504,89.7545045 L79.978276,89.7545045 Z"
                stroke="#046363"
                strokeWidth="1.242"
              ></path>
              <path
                d="M-1.70530257e-13,49.0820107 L-1.70530257e-13,7.10542736e-14 L43.1573547,7.10542736e-14 M157.971539,49.0820107 L157.971539,7.10542736e-14 L114.812113,7.10542736e-14 M108.874377,158.030488 L157.971539,158.030488 L157.971539,114.886452 M49.0971625,158.030488 L-1.70530257e-13,158.030488 L-1.70530257e-13,114.886452"
                stroke="#FFFFFF"
                strokeWidth="2.484"
              ></path>
            </g>
            <path
              d="M242.475338,43.6601574 L259.556968,43.6601574 L250.618098,22.4142418 L242.475338,43.6601574 Z M255.807093,10.9268293 L279.175938,64.597561 L268.381524,64.597561 L262.890417,51.5365303 L239.370515,51.5365303 L234.257049,64.597561 L223.538163,64.597561 L245.353576,10.9268293 L255.807093,10.9268293 Z"
              fill="#FFFFFF"
            ></path>
            <polygon
              fill="#FFFFFF"
              points="333.820181 10.9268293 333.820181 19.0330333 315.077156 19.0330333 315.077156 64.597561 304.873684 64.597561 304.873684 19.0330333 286.13066 19.0330333 286.13066 10.9268293"
            ></polygon>
            <polygon
              fill="#FFFFFF"
              points="397.40621 10.9268293 397.40621 19.0330333 378.663185 19.0330333 378.663185 64.597561 368.459713 64.597561 368.459713 19.0330333 349.716688 19.0330333 349.716688 10.9268293"
            ></polygon>
            <polygon
              fill="#FFFFFF"
              points="448.553707 10.9268293 448.553707 19.0330333 425.235607 19.0330333 425.235607 33.1334802 447.557139 33.1334802 447.557139 41.2396842 425.235607 41.2396842 425.235607 56.4913569 449.069858 56.4913569 449.069858 64.597561 415.28978 64.597561 415.28978 10.9268293"
            ></polygon>
            <polygon
              fill="#FFFFFF"
              points="478.955586 10.9268293 510.552597 48.3851529 510.552597 10.9268293 520.60414 10.9268293 520.60414 64.597561 511.771339 64.597561 479.983514 26.5242839 479.983514 64.597561 469.934023 64.597561 469.934023 10.9268293"
            ></polygon>
            <polygon
              fill="#FFFFFF"
              points="587.170763 10.9268293 587.170763 19.0330333 568.427739 19.0330333 568.427739 64.597561 558.224267 64.597561 558.224267 19.0330333 539.481242 19.0330333 539.481242 10.9268293"
            ></polygon>
            <polygon
              fill="#FFFFFF"
              points="606.047865 64.597561 616.976714 64.597561 616.976714 10.9268293 606.047865 10.9268293"
            ></polygon>
            <path
              d="M646.936918,37.3795846 C646.936918,40.9269861 647.741889,44.1155226 649.349767,46.9472565 C650.957645,49.7789904 653.19918,51.9734295 656.072308,53.5285113 C658.943371,55.0856556 662.192152,55.8631965 665.816586,55.8631965 C671.306901,55.8631965 675.818868,54.1410568 679.356612,50.6967774 C682.892293,47.252498 684.659101,42.8141212 684.659101,37.3795846 C684.659101,33.8342455 683.862386,30.6374592 682.266892,27.7933507 C680.671398,24.9492421 678.423671,22.7486157 675.52784,21.1914715 C672.62788,19.6363896 669.366715,18.8588487 665.740217,18.8588487 C662.115783,18.8588487 658.852554,19.642577 655.956722,21.2120959 C653.058826,22.7795524 650.831739,24.9739915 649.273398,27.7933507 C647.715056,30.6127099 646.936918,33.8094962 646.936918,37.3795846 M665.816586,9.94084628 C671.358502,9.94084628 676.343131,11.0834396 680.7746,13.3665637 C685.201941,15.6496878 688.731429,18.9413464 691.361002,23.2394771 C693.992638,27.5396702 695.307424,32.2523519 695.307424,37.3795846 C695.307424,42.3810082 694.023598,47.0173795 691.458011,51.2907609 C688.892424,55.5641423 685.369127,58.8805502 680.888121,61.2399847 C676.407116,63.5994192 671.358502,64.7791365 665.740217,64.7791365 C660.200364,64.7791365 655.164135,63.5808572 650.633593,61.1822363 C646.100986,58.7836154 642.571497,55.4878319 640.043063,51.2907609 C637.516692,47.0936899 636.251442,42.4573186 636.251442,37.3795846 C636.251442,32.4812831 637.516692,27.8779108 640.043063,23.5653431 C642.571497,19.2527753 646.055577,15.9054307 650.499431,13.5191844 C654.94122,11.1350006 660.047626,9.94084628 665.816586,9.94084628"
              fill="#FFFFFF"
            ></path>
            <polygon
              fill="#FFFFFF"
              points="724.269458 10.6303209 756.055496 47.9419726 756.055496 10.6303209 766.167172 10.6303209 766.167172 64.0908993 757.281529 64.0908993 725.303537 26.1667021 725.303537 64.0908993 715.193925 64.0908993 715.193925 10.6303209"
            ></polygon>
            <path
              d="M257.541028,96.5630291 C260.579278,96.5630291 263.553543,96.9074571 266.463823,97.596313 C269.374103,98.2851688 272.515554,99.4586988 275.886113,101.116903 L275.886113,110.837195 C272.591923,108.95006 269.547481,107.584723 266.750723,106.743247 C263.956028,105.899707 261.037492,105.478969 258.001306,105.478969 C254.195238,105.478969 250.762758,106.26476 247.697676,107.834279 C244.634658,109.403798 242.252769,111.616799 240.556138,114.473282 C238.857442,117.329765 238.009127,120.505927 238.009127,124.001767 C238.009127,127.573918 238.85125,130.774829 240.537561,133.606563 C242.221809,136.438297 244.597505,138.628611 247.660524,140.171319 C250.723542,141.714026 254.170469,142.485379 258.001306,142.485379 C260.936355,142.485379 263.737241,142.064641 266.40603,141.221102 C269.074819,140.379625 272.439185,138.86373 276.499129,136.667228 L276.499129,146.387521 C273.025369,148.200408 269.834381,149.487372 266.924101,150.252539 C264.013821,151.017705 260.61643,151.401319 256.736057,151.401319 C251.093003,151.401319 246.052645,150.27935 241.608792,148.03335 C237.167003,145.789413 233.674667,142.555502 231.135912,138.333682 C228.595093,134.111862 227.323651,129.487865 227.323651,124.45963 C227.323651,119.078717 228.632245,114.242288 231.249434,109.956532 C233.866622,105.670776 237.466287,102.366743 242.050494,100.044433 C246.632637,97.7241844 251.796837,96.5630291 257.541028,96.5630291"
              fill="#FFFFFF"
            ></path>
            <path
              d="M310.170657,129.856217 L327.442447,129.856217 L318.404066,108.693492 L310.170657,129.856217 Z M323.650827,97.25106 L347.279824,150.711638 L336.365242,150.711638 L330.813005,137.70175 L307.03127,137.70175 L301.860878,150.711638 L291.022665,150.711638 L313.080937,97.25106 L323.650827,97.25106 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M381.872528,120.212648 C384.63007,120.212648 386.780788,119.58154 388.326745,118.319325 C389.870638,117.05711 390.642585,115.186475 390.642585,112.711543 C390.642585,107.790555 387.476365,105.325936 381.143926,105.325936 L374.097333,105.325936 L374.097333,120.212648 L381.872528,120.212648 Z M384.438115,97.2514725 C389.443384,97.2514725 393.507456,98.6993073 396.634459,101.597039 C399.763526,104.492709 401.32806,108.223668 401.32806,112.787854 C401.32806,117.407725 399.742886,121.144872 396.578731,124.001355 C393.412511,126.859901 389.236981,128.287111 384.05627,128.287111 L374.097333,128.287111 L374.097333,150.712051 L363.758614,150.712051 L363.758614,97.2514725 L384.438115,97.2514725 Z"
              fill="#FFFFFF"
            ></path>
            <polygon
              fill="#FFFFFF"
              points="419.987496 150.713288 430.328279 150.713288 430.328279 97.25271 419.987496 97.25271"
            ></polygon>
            <polygon
              fill="#FFFFFF"
              points="496.936128 97.251885 496.936128 105.326348 477.940874 105.326348 477.940874 150.712463 467.600092 150.712463 467.600092 105.326348 448.604838 105.326348 448.604838 97.251885"
            ></polygon>
            <path
              d="M522.3373,129.856217 L539.60909,129.856217 L530.57071,108.693492 L522.3373,129.856217 Z M535.81747,97.25106 L559.446467,150.711638 L548.531885,150.711638 L542.979649,137.70175 L519.197913,137.70175 L514.027522,150.711638 L503.189308,150.711638 L525.24758,97.25106 L535.81747,97.25106 Z"
              fill="#FFFFFF"
            ></path>
            <polygon
              fill="#FFFFFF"
              points="586.265627 97.251885 586.265627 142.638 612.231105 142.638 612.231105 150.712463 575.924845 150.712463 575.924845 97.251885"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
