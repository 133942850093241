import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { animated, config, useChain, useSpring } from 'react-spring';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Logo from '../components/Logo';

const Wrapper = styled(Flex)`
  flex: 1;
  background-color: ${props => props.theme.colors.blue};
`;

const Container = styled(Flex)`
  max-width: 1280px;
`;

const Highlight = styled.span`
  color: #caae78;
`;

const StyledLogo = styled(animated.div)`
  width: 100%;
  max-width: 768px;
`;

const IndexPage = () => {
  const logoRef = useRef<any>();
  const contentRef = useRef<any>();

  const logo = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0px)' },
    config: config.molasses,
    ref: logoRef,
  });

  const content = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0px)' },
    config: config.molasses,
    ref: contentRef,
  });

  useChain([logoRef, contentRef], [0.1, 0.5]);

  return (
    <Layout>
      <Helmet>
        <title>Attention Capital</title>
      </Helmet>

      <Wrapper
        py={[5, 5, 6]}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Container flexDirection="column" mx={3}>
          <Flex mb={[4, 5]}>
            <StyledLogo style={logo}>
              <Logo />
            </StyledLogo>
          </Flex>

          <Flex mb={[0, 0, 0]} flexDirection="column">
            <animated.div style={content}>
              <Text
                fontFamily="big-caslon-fb"
                fontSize={[5, 5, 6]}
                color="white"
                lineHeight={1.4}
              >
                <Highlight>Attention Capital</Highlight> is a media and technology holding company
              </Text>
            </animated.div>
          </Flex>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
